/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { useResetPasswordMutation } from 'store/api'
import { isValidString } from 'helpers/isValidString'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import { toast } from 'react-toastify'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import AuthContainer from 'components/AuthComponents/AuthContainer'
import AuthWelcomeMessage from 'components/AuthComponents/AuthWelcomeMessage'
import AuthErrorMessage from 'components/AuthComponents/AuthErrorMessage'
import AuthInputContainer from 'components/AuthComponents/AuthInputContainer'
import AuthButtonsContainer from 'components/AuthComponents/AuthButtonsContainer'
import FormikPasswordInput from 'components/AuthComponents/FormikPasswordInput'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'

/* Type declarations -------------------------------------------------------- */
interface NewPasswordRequest {
  newPassword: string;
  newPasswordRepeat: string;
}

const NewPasswordFormSchema = Yup.object().shape<Shape<NewPasswordRequest>>({
  newPassword: Yup.string().required('Mot de passe invalide'),
  newPasswordRepeat: Yup.string().when('newPassword', {
    is: (newPassword: string) => isValidString(newPassword),
    then: (schema) => schema.oneOf([ Yup.ref('newPassword') ], 'Les mots de passe ne correspondent pas').required('Les mots de passe ne correspondent pas'),
  }),
})

/* Component declaration ---------------------------------------------------- */
interface NewPasswordPageProps {}

const NewPasswordPage: React.FC<NewPasswordPageProps> = () => {
  const { token = '' } = useParams<{ token: string }>()
  const navigate = useNavigate()
  const [ errorMessage, setErrorMessage ] = useState<string | null>(null)

  const [
    resetPassword,
  ] = useResetPasswordMutation()

  const onSubmit = (values: NewPasswordRequest, { setSubmitting }: FormikHelpers<NewPasswordRequest> ): void | Promise<void> => {
    setErrorMessage(null)
    resetPassword({ newPassword: values.newPassword, token })
      .then((pResult) => {
        setSubmitting(false)

        if (isApiError(pResult)) {
          setErrorMessage('Le token semble invalide.')
        } else {
          toast.success('Le mot de pass à bien été ajouté.')
          navigate(`/connexion`)
        }
      })
      .catch((error) => {
        setSubmitting(false)
        setErrorMessage('Une erreur est survenue.')
        console.error(error)
      })
  }

  const formikForm = useForm<NewPasswordRequest>(
    {
      initialValues: {
        newPassword: '',
        newPasswordRepeat: '',
      },
      onSubmit: onSubmit,
      validationSchema: NewPasswordFormSchema,
    },
  )

  const onGoBackClick = () => {
    navigate('/connexion')
  }

  return (
    <AuthContainer>
      <Form form={formikForm}>
        {formikForm.isSubmitting && <Loader />}
        <AuthWelcomeMessage>
          {'Bienvenue dans '}
          <b>
            l'Extranet Entreprise iREN
          </b>
        </AuthWelcomeMessage>
        {
          isValidString(errorMessage) && (
            <AuthErrorMessage>
              {errorMessage}
            </AuthErrorMessage>
          )
        }
        <div>
          <AuthInputContainer>
            <FormBoldTitle>
              Nouveau mot de passe
            </FormBoldTitle>
            <FormikPasswordInput
              placeholder="Nouveau mot de passe"
              name="newPassword"
              autoComplete="new-password"
            />
          </AuthInputContainer>
          <AuthInputContainer>
            <FormBoldTitle>
              Confirmer le mot de passe
            </FormBoldTitle>
            <FormikPasswordInput
              placeholder="Confirmer le mot de passe"
              name="newPasswordRepeat"
              autoComplete="new-password"
            />
          </AuthInputContainer>
        </div>
        <AuthButtonsContainer>
          <Button
            type="submit"
            disabled={formikForm.isSubmitting}
            variant="contained"
            fullWidth
          >
            {
              formikForm.isSubmitting ?
                `Envoi en cours...` :
                `Valider`
            }
          </Button>
          <Button
            variant="outlined"
            fullWidth
            disabled={formikForm.isSubmitting}
            onClick={onGoBackClick}
          >
            Revenir à l'écran de connexion
          </Button>
        </AuthButtonsContainer>
      </Form>
    </AuthContainer>
  )
}

export default NewPasswordPage
