/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { useAuthInfo } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
// Layouts
import AuthLayout from 'layouts/AuthLayout/AuthLayout'
import MainLayout from 'layouts/MainLayout/MainLayout'
import CaseLayout from 'layouts/CaseLayout/CaseLayout'
import SettingsLayout from 'layouts/SettingsLayout/SettingsLayout'
// Auth Pages
import LoginPage from 'pages/AuthPages/LoginPage/LoginPage'
import NewPasswordPage from 'pages/AuthPages/NewPasswordPage/NewPasswordPage'
import ExternalLoginPage from 'pages/AuthPages/ExternalLoginPage/ExternalLoginPage'
import ForgotPasswordPage from 'pages/AuthPages/ForgotPasswordPage/ForgotPasswordPage'
// Main Pages
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage'
import CaseListPage from 'pages/CaseListPage/CaseListPage'
import CaseWorkflowPage from 'pages/CaseWorkflowPage/CaseWorkflowPage'
import MessagesPage from 'pages/MessagesPage/MessagesPage'
import MessagesContainerPage from 'pages/MessagesContainerPage/MessagesContainerPage'
import QuoteInvoicePage from 'pages/QuoteInvoicePages/QuoteInvoicePage'
import DocumentsPage from 'pages/DocumentsPage/DocumentsPage'
import SinappsReportPage from 'pages/SinappsReportPage/SinappsReportPage'
import SinappsWaterReportPage from 'pages/SinappsReportPage/SinappsWaterReportPage'
import VacationsPage from 'pages/VacationsPage/VacationsPage'

/* Component declaration ---------------------------------------------------- */
interface RouterProps {}

const Router: React.FC<RouterProps> = () => {
  const authInfo = useAuthInfo()

  console.log('[DEBUG] authInfo :', authInfo)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  // $crisp.push([ 'set', 'user:nickname', [ `${window.location.host.replace('i-ren.fr', '')} - ${authInfo?.nom}` ] ])

  return (
    <Routes>
      <Route
        path="/"
        element={
          authInfo !== null ?
            <MainLayout /> :
            <Navigate
              to="/connexion"
              replace
            />
        }
      >
        <Route path="dossiers">
          <Route
            index
            element={<CaseListPage />}
          />
          <Route
            path=":caseId"
            element={<CaseLayout />}
          >
            <Route
              index
              element={<CaseWorkflowPage />}
            />
            {
              !authInfo?.lectureSeule &&
                <>
                  <Route
                    path="nouveau-devis"
                    element={<QuoteInvoicePage />}
                  />
                  <Route
                    path="devis"
                    element={<QuoteInvoicePage edit />}
                  />
                  <Route
                    path="visualisation-devis"
                    element={<QuoteInvoicePage readOnly />}
                  />
                  <Route
                    path="nouvelle-facture"
                    element={<QuoteInvoicePage isInvoice />}
                  />
                  <Route
                    path="facture"
                    element={
                      <QuoteInvoicePage
                        isInvoice
                        edit
                      />
                    }
                  />
                  <Route
                    path="visualisation-facture"
                    element={
                      <QuoteInvoicePage
                        isInvoice
                        readOnly
                      />
                    }
                  />
                  <Route
                    path="compte-rendu-sinapps"
                    element={<SinappsReportPage />}
                  />
                  <Route
                    path="compte-rendu-sinapps-rdf"
                    element={<SinappsWaterReportPage />}
                  />
                </>
            }
          </Route>
        </Route>
        <Route
          path="messagerie"
          element={<MessagesContainerPage />}
        />
        <Route
          path="parametres"
          element={<SettingsLayout />}
        >
          <Route
            index
            element={
              <Navigate
                to="conges"
                replace
              />
            }
          />
          <Route
            path="conges"
            element={<VacationsPage />}
          />
        </Route>
        <Route
          path="messagerie/:caseId"
          element={<CaseLayout />}
        >
          <Route
            path=""
            element={<MessagesPage />}
          />
        </Route>
        <Route
          path="documents/:caseId"
          element={<CaseLayout />}
        >
          <Route
            path=""
            element={<DocumentsPage />}
          />
        </Route>
        <Route
          index
          element={
            <Navigate
              to="/dossiers"
              replace
            />
          }
        />
        <Route
          path="*"
          element={<NotFoundPage />}
        />
      </Route>
      <Route
        element={
          authInfo === null ?
            <AuthLayout /> :
            <Navigate
              to="/"
              replace
            />
        }
      >
        <Route
          path="/connexion"
          element={<LoginPage />}
        />
        <Route
          path="/mot-de-passe-oublie"
          element={<ForgotPasswordPage />}
        />
        <Route
          path="/nouveau-mot-de-passe/:token"
          element={<NewPasswordPage />}
        />
      </Route>
      <Route element={<AuthLayout />}>
        <Route
          path="/connexion-automatique"
          element={<ExternalLoginPage />}
        />
      </Route>
      <Route
        path="*"
        element={<NotFoundPage />}
      />
    </Routes>
  )
}

export default Router
