/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useLocation,
  useNavigate,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetDepartmentListQuery,
  useGetCaseListQuery,
  useGetDisasterListQuery,
} from 'store/api'
import {
  exportToXLSX,
  getRowsComparator,
} from 'helpers/tableUtils'
import DateUtils from 'helpers/DateUtils'
import { getCaseStatusColor } from 'helpers/caseStatusColor'
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks'
import { setRouterLastPath } from 'store/slices/routerHistorySlice'
import { getCaseListPeriod } from 'store/slices/caseListFilterSlice'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  Chip,
  Collapse,
} from '@mui/material'
import KeyboardArrowDownRounded from '@mui/icons-material/KeyboardArrowDownRounded'
import Footer from 'layouts/Footer/Footer'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import LongButton from 'components/LongButton/LongButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import ScrollableFiltersContainer from 'components/ScrollableFiltersContainer/ScrollableFiltersContainer'
import RoundedChip from 'components/RoundedChip/RoundedChip'
import Table from 'components/Table/Table'
import CheckableSortingButton from 'components/CheckableSortingButton/CheckableSortingButton'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import CaseListFilters from './CaseListComponents/CaseListFilters'
import CaseListMobileCard from './CaseListComponents/CaseListMobileCard'
// import CaseListDocuments from './CaseListComponents/CaseListDocuments'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type {
  RechercheDossierListParams,
  CodeLabel,
  DossierRecherche,
  Anomalie,
} from 'API/__generated__/Api'
import type {
  ColumnHeader,
  DataName,
  Order,
} from 'types/Table'

/* Type declarations -------------------------------------------------------- */
const myCasesSchema = Yup.mixed<RechercheDossierListParams>().required()

type CaseListForm = FormikContextType<RechercheDossierListParams>

interface OpenedMenu {
  filter: boolean;
  sort: boolean;
}

/* Internal Variables ------------------------------------------------------- */
const periodList = [
  {
    value: new Date('2000-01-01').toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: 'Tout',
  },
  {
    value: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: '7 derniers jours',
  },
  {
    value: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: '30 derniers jours',
  },
  {
    value: new Date(Date.now() - 93 * 24 * 60 * 60 * 1000).toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: '3 derniers mois',
  },
  {
    value: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: "L'année passée",
  },
]

const RESULT_LIMIT = 10

/* Styled components -------------------------------------------------------- */
const FilterDesktopContainer = styled.div`
  display: grid;
  grid-template-columns: 75% 24%;
  justify-content: space-between;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }

  @media ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`

const FilterMobileContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const FormTitleWithArrow = styled(FormBoldTitle)`
  align-items: center;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 10px;
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 36px;
`

const BoldSeparator = styled.div`
  margin-top: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.lightgrey};
`

const CustomChip = styled(RoundedChip)`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`

const TableCardContainer = styled(Card)`
  margin-top: 20px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }
`

const TableCardContentContainer = styled.div`
  padding: 0px 5px 5px;
`

const StatusChipContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .status-chip {
    font-size: .8rem;
  }
`

const MobileCardContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const Link = styled.a`
  color: ${(props) => props.theme.palette.primary.main};
`

/* Component declaration ---------------------------------------------------- */
interface CaseListPageProps {}

const CaseListPage: React.FC<CaseListPageProps> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const period = useAppSelector(getCaseListPeriod)
  const [ caseList, setCaseList ] = useState<DossierRecherche[]>([])
  const [ openedMenu, setOpenedMenu ] = useState<OpenedMenu>({ filter: false, sort: false })
  const [ statusFilters, setStatusFilters ] = useState<{status: CodeLabel; amount: number | null}[]>([])
  const [ totalRows, setTotalRows ] = useState<number | undefined>()
  const [ order, setOrder ] = useState<Order>('asc')
  const [ orderBy, setOrderBy ] = useState<DataName>('')

  const {
    currentData: departmentList = [],
    isFetching: isFetchingDepartmentList,
  } = useGetDepartmentListQuery()
  const {
    currentData: disasterNatureList = [],
    isFetching: isFetchingDisasterNatureList,
  } = useGetDisasterListQuery()
  // const {
  //   currentData: documentInfosList = [],
  //   isFetching: isFetchingDocumentInfosList,
  // } = useGetCaseDocumentInfosListQuery(
  //   { dossiers: caseResultList?.resultats?.map((cases) => cases.id) },
  //   { skip: caseResultList?.resultats?.length === 0 || !caseResultList?.resultats },
  // )

  const formikForm: CaseListForm = useForm<RechercheDossierListParams>(
    {
      initialValues: {
        Limite: RESULT_LIMIT,
        CompagnieAssurance: [],
        DateDebut: period,
        DateFin: new Date().toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        Departement: '',
        IndexDebut: 0,
        NatureSinistre: [],
        RechercheLibre: '',
        Statut: [],
      },
      validationSchema: myCasesSchema,
    },
  )

  const parseFilters = (params: RechercheDossierListParams) => {
    const result = structuredClone(params)

    for (const key in params) {
      const typedKey = key as keyof RechercheDossierListParams
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result[typedKey] = params[typedKey] === null ? '' : params[typedKey]
    }

    return result
  }

  const {
    currentData: caseResultList,
    isFetching: isFetchingCaseResultList,
  } = useGetCaseListQuery(parseFilters(formikForm.values))

  const saveLastPath = () => {
    dispatch(setRouterLastPath(location.pathname))
  }

  const handleNavigate = (caseId: string) => {
    saveLastPath()
    navigate(caseId)
  }

  useEffect(() => {
    if (caseResultList?.resultats?.length !== undefined) {
      setCaseList(caseResultList?.resultats?.slice().sort(getRowsComparator(order, orderBy)) || [])
    }
    if (caseResultList?.totalResultats !== undefined && caseResultList.totalResultats !== totalRows) {
      setTotalRows(caseResultList.totalResultats)
    }
    if (caseResultList?.nombreResultatParStatut) {
      setStatusFilters(
        caseResultList?.nombreResultatParStatut?.map(({ nombreResultat, statutComposeDossier }) => ({
          status: statutComposeDossier || { code: '', libelle: '' },
          amount: nombreResultat || 0,
        })),
      )
    }
  }, [ caseResultList ])

  const cols: ColumnHeader[] = [
    {
      id: 'id',
      label: 'Ref compagnie',
      render: (companyRef: string, row) => (
        <Link
          href={`/dossiers/${(row as DossierRecherche).id}`}
          onClick={(e) => {e.stopPropagation(); saveLastPath()}}
        >
          {companyRef}
        </Link>
      ),
    },
    {
      id: 'assure',
      label: 'Assuré',
    },
    {
      id: 'referenceCompagnie',
      label: 'Ref sinistre',
    },
    {
      id: 'natureSinistre.libelle',
      label: 'Type de sinsitre',
    },
    {
      id: 'ville',
      label: 'Ville',
    },
    {
      id: 'dateSinistre',
      label: 'Date sinistre',
      render: (date: string) => DateUtils.APIStrToLocalDateString(date),
      renderForExport: (date: string) => DateUtils.APIStrToLocalDateString(date),
    },
    {
      id: 'statutComposeDossier',
      label: 'Statut',
      sortId: 'statutComposeDossier.code',
      render: (status: CodeLabel) => {
        return (
          <StatusChipContainer>
            <ColoredSquareChip
              color={getCaseStatusColor(status.code)}
              className="status-chip"
            >
              {status.libelle}
            </ColoredSquareChip>
          </StatusChipContainer>
        )
      },
      renderForExport: (status: CodeLabel) => status.libelle || '',
    },
    {
      id: 'derniereAnomalieNonLevee',
      label: 'Anomalie',
      render: (anomalie: Anomalie) => anomalie.anomalieType?.nom ?
        (
          <StatusChipContainer>
            <Chip
              color="warning"
              label={anomalie.anomalieType.nom}
            />
          </StatusChipContainer>
        ) :
        (<div />),
      renderForExport: (anomalie: Anomalie) => anomalie.anomalieType?.nom || '',
    },
    // {
    //   id: 'id',
    //   label: 'PJ',
    //   removeFromExport: true,
    //   render: (id: string, row) => (
    //     <CaseListDocuments
    //       // documentsInfo={documentInfosList.find((documentInfo) => documentInfo.dossier === id)}
    //       caseId={(row as DossierRecherche).id}
    //       // isFetching={isFetchingDocumentInfosList}
    //       isFetching={false}
    //     />
    //   ),
    // },
    {
      id: 'missionUrgente',
      label: 'Urgent',
      removeFromExport: true,
      render: (urgent: boolean) => (
        <Chip
          color={urgent ? 'error' : 'default'}
          label={urgent ? 'Oui' : 'Non'}
        />
      ),
    },
  ]

  const exportDataToXLSX = () => {
    exportToXLSX(cols.filter((col) => !col.removeFromExport), caseList, `vos dossiers_${new Date().toISOString().replace(/[-T:]/g, '').slice(0, 14)}`)
  }

  const onStatusFilterClick = (status: string) => {
    if (formikForm.values.Statut?.includes(status)) {
      formikForm.setFieldValue('Statut', formikForm.values.Statut.filter((stat) => stat !== status))
    } else {
      formikForm.setFieldValue('Statut', [ ...formikForm.values.Statut || [], status ])
    }
  }

  const handleSort = (property: DataName) => () => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setCaseList(caseList.slice().sort(getRowsComparator(isAsc ? 'desc' : 'asc', property)))
    setOpenedMenu({ ...openedMenu, sort: false })
  }

  return (
    <Form form={formikForm}>
      <LargeTitle>
        Vos dossiers
        <LongButton
          variant="contained"
          onClick={exportDataToXLSX}
        >
          Exporter
        </LongButton>
      </LargeTitle>
      <FilterDesktopContainer>
        <CaseListFilters
          disasterNatureList={disasterNatureList}
          isFetchingDisasterNatureList={isFetchingDisasterNatureList}
          departmentList={departmentList}
          isFetchingDepartmentList={isFetchingDepartmentList}
          periodList={periodList}
        />
      </FilterDesktopContainer>
      <FilterMobileContainer>
        <BoldSeparator />
        <FormTitleWithArrow onClick={() => setOpenedMenu({ ...openedMenu, filter: !openedMenu.filter })}>
          Filtres
          <DropDownArrow open={openedMenu.filter} />
        </FormTitleWithArrow>
        <Collapse
          in={openedMenu.filter}
          timeout="auto"
          unmountOnExit
        >
          <CaseListFilters
            size="small"
            disasterNatureList={disasterNatureList}
            isFetchingDisasterNatureList={isFetchingDisasterNatureList}
            departmentList={departmentList}
            isFetchingDepartmentList={isFetchingDepartmentList}
            periodList={periodList}
          />
        </Collapse>
        <BoldSeparator />
        <FormTitleWithArrow onClick={() => setOpenedMenu({ ...openedMenu, sort: !openedMenu.sort })}>
          Trier par
          <DropDownArrow open={openedMenu.sort} />
        </FormTitleWithArrow>
        <Collapse
          in={openedMenu.sort}
          timeout="auto"
          unmountOnExit
        >
          {
            cols.map((col) => (
              <CheckableSortingButton
                key={col.id}
                checked={orderBy === col.id}
                label={col.label}
                onChange={handleSort(col.sortId ?? col.id)}
                order={order}
              />
            ))
          }
        </Collapse>
        <BoldSeparator />
      </FilterMobileContainer>
      <FormBoldTitle>
        Statuts
      </FormBoldTitle>
      <ScrollableFiltersContainer>
        {
          statusFilters.map((filter) => (
            <CustomChip
              variant={formikForm.values.Statut?.includes(filter.status.code) ? 'contained' : 'outlined'}
              key={filter.status.code}
              onClick={() => onStatusFilterClick(filter.status.code)}
            >
              {filter.status.libelle}
              <b>
                {filter.amount}
              </b>
            </CustomChip>
          ))
        }
      </ScrollableFiltersContainer>
      <LoadingOverlay isLoading={isFetchingCaseResultList}>
        <TableCardContainer>
          <TableCardContentContainer>
            <Table
              rows={caseList}
              setRows={(rows) => setCaseList(rows as DossierRecherche[])}
              colHeaders={cols}
              onRowClick={(row) => handleNavigate((row as DossierRecherche).id)}
              limit={RESULT_LIMIT}
              pagination={
                {
                  setServerLimit: (limit) => formikForm.setFieldValue('Limite', limit),
                  setServerStartIndex: (startIndex) => formikForm.setFieldValue('IndexDebut', startIndex),
                  totalRows,
                }
              }
              sorting={{ setOrder, setOrderBy }}
            />
          </TableCardContentContainer>
        </TableCardContainer>
        <MobileCardContainer>
          {
            caseList.map((myCase, index) => (
              <CaseListMobileCard
                key={`${myCase.id}-${index}`}
                myCase={myCase}
                // isFetchingDocumentsInfo={isFetchingDocumentInfosList}
                isFetchingDocumentsInfo={false}
                // documentsInfo={documentInfosList.find((docInfos) => docInfos.dossier === myCase.id)}
                handleNavigate={() => handleNavigate(myCase.id)}
              />
            ))
          }
        </MobileCardContainer>
      </LoadingOverlay>
      <Footer />
    </Form>
  )
}

export default CaseListPage
