/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'
import { getAddress } from 'helpers/getAddress'
import {
  useIsDesktop,
  useIsMobile,
  useIsTabletPortrait,
} from 'helpers/hooks/useIsMedia'

/* Component imports -------------------------------------------------------- */
import {
  // Button,
  Collapse,
} from '@mui/material'
import VignetteRendererWithFilename from 'components/MediaRenderer/VignetteRendererWithFilename'
import DropdownArrow from 'components/DropdownArrow/DropdownArrow'
import CaseLayoutSummaryEditInsuredModal from './CaseLayoutSummaryEditInsuredModal'

/* Type declarations -------------------------------------------------------- */
import type {
  CoordoneesRequest,
  Document,
  Dossier,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface SummaryContainerProps {
  isOpen: boolean;
}

const SummaryContainer = styled.div<SummaryContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${(props) => props.isOpen ? '60px' : '0px'};
`

interface BoldTitleProps {
  isDropdown?: boolean;
}

const BoldTitle = styled.div<BoldTitleProps>`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  margin: 3px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${(props) => props.theme.media.desktop} {
    cursor: ${(props) => props.isDropdown ? 'pointer' : 'initial'};
  }
`

const BoldSeparator = styled.div`
  width: 200% !important;
  margin: 15px -15px 5px -15px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const GridContainer = styled.div`
  display: grid;
  gap: 20px;
  align-items: stretch;
  justify-content: stretch;

  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
`

// const EditButton = styled(Button)`
//   height: 30px;
//   text-decoration: underline;
// `

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutSummaryProps {
  summary: Dossier;
  documents: Document[];
}

const CaseLayoutSummary: React.FC<CaseLayoutSummaryProps> = ({ summary, documents }) => {
  const isDesktop = useIsDesktop()
  const isMobile = useIsMobile()
  const isTablet = useIsTabletPortrait()
  const [ expanded, setExpanded ] = useState<boolean>(false)
  const [ openEditModal, setOpenEditModal ] = useState<boolean>(false)

  const handleExpandClick = (): void => {
    setExpanded(!expanded)
  }

  return (
    <SummaryContainer isOpen={expanded}>
      <BoldTitle>
        Informations Sinistre
      </BoldTitle>
      <b>
        {summary.natureSinistre?.libelle}
      </b>
      <div>
        {'Date : '}
        <b>
          {DateUtils.APIStrToLocalDateString(summary.dateSinistre)}
        </b>
      </div>
      <div>
        {'Lieu : '}
        <b>
          {getAddress(summary.adresseSinistre)}
        </b>
      </div>
      <div>
        {'Montant estimé : '}
        <b>
          {`${(summary.montantEstime || 0).toFixed(2)} €`}
        </b>
      </div>
      <div>
        {'Description : '}
        <b>
          {summary.descriptionDommages}
        </b>
      </div>
      <BoldSeparator />
      <BoldTitle>
        Assuré
        {/* <EditButton onClick={() => setOpenEditModal(true)}>
          Modifier
        </EditButton> */}
      </BoldTitle>
      <div>
        {'Nom : '}
        <b>
          {[ summary.assure?.nom, summary.assure?.prenom ].join(' ')}
        </b>
      </div>
      <div>
        {'Adresse : '}
        <b>
          {getAddress(summary.assure?.adresse)}
        </b>
      </div>
      <div>
        {'Téléphone portable : '}
        <b>
          <a
            href={`tel:${summary.assure?.telephonePortable}`}
            target="_self"
          >
            {summary.assure?.telephonePortable}
          </a>
        </b>
      </div>
      <div>
        {'Téléphone fixe : '}
        <b>
          <a
            href={`tel:${summary.assure?.telephoneFixe}`}
            target="_self"
          >
            {summary.assure?.telephoneFixe}
          </a>
        </b>
      </div>
      <div>
        {'Email : '}
        <b>
          <a
            href={`mailto:${summary.assure?.email}`}
            target="_self"
          >
            {summary.assure?.email}
          </a>
        </b>
      </div>
      <div>
        {'N° de contrat : '}
        <b>
          {summary.numeroContrat}
        </b>
      </div>
      <BoldSeparator />
      <BoldTitle>
        Assurance
      </BoldTitle>
      <div>
        {'Ref sinistre : '}
        <b>
          {summary.referenceCompagnie}
        </b>
      </div>
      <div>
        {'N° de contrat : '}
        <b>
          {summary.numeroContrat}
        </b>
      </div>
      <BoldSeparator />
      <BoldTitle>
        Missionnement
      </BoldTitle>
      <div>
        {'Date missionnement : '}
        <b>
          {DateUtils.APIStrToLocalDateString(summary.dateMissionnement)}
        </b>
      </div>
      <div>
        {'Type de prestation : '}
        <b>
          {summary.typePrestation?.libelle}
        </b>
      </div>
      <div>
        {'Gestionnaire : '}
        <b>
          {summary.gestionnaire}
        </b>
      </div>
      <BoldSeparator />
      <BoldTitle
        onClick={handleExpandClick}
        isDropdown={isDesktop && documents.length > 0}
      >
        {`Documents ${isDesktop ? (`(${documents.length} disponible${documents.length > 1 ? 's' : ''})`) : ''}`}
        {
          isDesktop && documents.length > 0 &&
            <DropdownArrow expanded={expanded.toString()} />
        }
      </BoldTitle>
      <Collapse
        in={isMobile || isTablet ? true : expanded}
        timeout="auto"
        unmountOnExit
      >
        <GridContainer>
          {
            documents.map((document, index) => (
              <VignetteRendererWithFilename
                key={`${document.libelle}-${index}`}
                document={{ ...document, fileName: document.libelle }}
                onClick={() => window.open(document.url || '', '_blank', 'noreferrer')}
                width="min(100px, 100%)"
                height="120px"
              />
            ))
          }
        </GridContainer>
      </Collapse>
      {
        openEditModal &&
          <CaseLayoutSummaryEditInsuredModal
            open
            handleClose={() => setOpenEditModal(false)}
            insured={summary.assure as CoordoneesRequest}
          />
      }
    </SummaryContainer>
  )
}

export default CaseLayoutSummary
