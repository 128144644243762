export enum MainTabs {
  Dossier = 'dossiers',
  Messagerie = 'messagerie',
  Documents = 'documents',
}

export enum SettingsTabs {
  Conges = 'conges'
}

export const mainTabs: {label: string; path: string; caseOnly: boolean; rootOnly: boolean}[] = [
  {
    label: 'Suivi',
    path: MainTabs.Dossier,
    caseOnly: false,
    rootOnly: false,
  },
  {
    label: 'Messagerie',
    path: MainTabs.Messagerie,
    caseOnly: false,
    rootOnly: false,
  },
  // {
  //   label: 'Pièces jointes',
  //   path: MainTabs.Documents,
  //   caseOnly: true,
  // },
]

export const settingsTabs: {label: string; path: string}[] = [
  {
    label: 'Congés',
    path: SettingsTabs.Conges,
  },
]
