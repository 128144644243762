/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { usePatchMyEstablishementMutation } from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { TextField } from 'formik-mui'
import CloseButton from 'components/CloseButton/CloseButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import AddressAutocomplete from 'components/FieldWithInputAdornment/AddressAutocomplete'

/* Type imports ------------------------------------------------------------- */
import {
  Field,
  type FormikContextType,
  type FormikHelpers,
} from 'formik'
import type { Feature } from 'geojson'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  CoordonneesEtablissementUpdate,
  MonEtablissement,
  MonEtablissementUpdatePayload,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const companySchema = Yup.object().shape<Shape<MonEtablissementUpdatePayload>>({
  coordonnees: Yup.object().shape<Shape<CoordonneesEtablissementUpdate>>({
    adresse1: Yup.string().required("L'adresse est obligatoire"),
    adresse2: Yup.string().nullable(),
    adresse3: Yup.string().nullable(),
    ville: Yup.string().required('La ville est obligatoire'),
    codePostal: Yup.string().required('Le code postal est obligatoire'),
  }),
  email: Yup.string().email("L'email est invalide").nullable(),
  telephone: Yup.string().test(
    'personnal-phone',
    'Le portable est invalide',
    (item = '') => !item || (/^(06|07)/).test(item) && item.replaceAll(' ', '').length === 10,
  ),
})

type CompanyForm = FormikContextType<MonEtablissementUpdatePayload>

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: -20px;
  text-transform: uppercase;
  text-align: center;

  @media ${(props) => props.theme.media.mobile.portrait} {
    font-size: 1.2rem;
    margin-top: 0px;
  }
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin: 20px;
`

const FormButton = styled(Button)`
  margin: 0px 5px;
`

const DoubleContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    grid-template-columns: 1fr;
    gap: 0px;
  }
`

const BlankDiv = styled.div`
  height: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface SettingsLayoutSummaryEditModalProps {
  open: boolean;
  handleClose: () => void;
  company: MonEtablissement;
}

const SettingsLayoutSummaryEditModal: React.FC<SettingsLayoutSummaryEditModalProps> = ({
  open,
  handleClose,
  company,
}) => {
  const [
    submitUpdateMyEstablishement,
  ] = usePatchMyEstablishementMutation()

  const onSubmit = (values: MonEtablissementUpdatePayload, { setSubmitting }: FormikHelpers<MonEtablissementUpdatePayload>) => {
    submitUpdateMyEstablishement(values)
      .then((response) => {
        if (!isApiError(response)) {
          handleClose()
        }
      })
      .catch(console.error)
      .finally(() => {
        setSubmitting(false)
      })
  }

  const formikForm: CompanyForm = useForm<MonEtablissementUpdatePayload>(
    {
      onSubmit: onSubmit,
      initialValues: {
        ...company,
      },
      validationSchema: companySchema,
    },
  )

  const onAddressChange = (newAddress: Feature) => {
    formikForm.setValues({
      ...formikForm.values,
      coordonnees: {
        ...formikForm.values.coordonnees,
        adresse1: newAddress.properties?.name as string,
        codePostal: newAddress.properties?.postcode as string,
        ville: newAddress.properties?.city as string,
      },
    })
  }

  const addressErrorProps = () => {
    const addressTouched = (formikForm.touched.coordonnees as unknown as CoordonneesEtablissementUpdate)?.adresse1
    const addressError = (formikForm.touched.coordonnees as unknown as CoordonneesEtablissementUpdate)?.adresse1
    return ({
      error: addressTouched && addressError !== undefined,
      helperText: addressTouched && addressError !== undefined ? addressError : undefined,
    })
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <Form form={formikForm}>
        <DialogTitleContainer>
          Informations générales
          <CloseButton handleClose={handleClose} />
        </DialogTitleContainer>
        {
          <DialogContentContainer>
            <FormBoldTitle bigger>
              Société
            </FormBoldTitle>
            <div>
              <FormBoldTitle required>
                Raison sociale
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Raison Sociale"
                name="raisonSociale"
                size="small"
              />
            </div>
            <div>
              <FormBoldTitle>
                SIRET
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="SIRET"
                name="siret"
                size="small"
              />
            </div>
            <FormBoldTitle bigger>
              Siège Social
            </FormBoldTitle>
            <FormBoldTitle required>
              Adresse
            </FormBoldTitle>
            <AddressAutocomplete
              placeholder="Adresse"
              value={formikForm.values.coordonnees?.adresse1 || ''}
              onValueChange={(newValue: string) => formikForm.setFieldValue('coordonnees.adresse1', newValue)}
              onAddressChange={onAddressChange}
              size="small"
              {...addressErrorProps}
            />
            <BlankDiv />
            <Field
              component={TextField}
              placeholder="Adresse"
              name="coordonnees.adresse2"
              size="small"
            />
            <BlankDiv />
            <Field
              component={TextField}
              placeholder="Adresse"
              name="coordonnees.adresse3"
              size="small"
            />
            <DoubleContainer>
              <div>
                <FormBoldTitle required>
                  Code postal
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Code postal"
                  name="coordonnees.codePostal"
                  size="small"
                />
              </div>
              <div>
                <FormBoldTitle required>
                  Ville
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Ville"
                  name="coordonnees.ville"
                  size="small"
                />
              </div>
            </DoubleContainer>
            <div>
              <FormBoldTitle>
                Email
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Email"
                name="email"
                size="small"
              />
            </div>
            <div>
              <FormBoldTitle>
                Téléphone
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Téléphone"
                name="telephone"
                size="small"
              />
            </div>
          </DialogContentContainer>
        }
        <DialogActionContainer>
          <FormButton
            variant="outlined"
            onClick={handleClose}
          >
            Annuler
          </FormButton>
          <FormButton
            variant="contained"
            type="submit"
          >
            Valider
          </FormButton>
        </DialogActionContainer>
      </Form>
    </Dialog>
  )
}

export default SettingsLayoutSummaryEditModal
